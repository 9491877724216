<template :id="'CtvdTrajectoryTrend' + this.widgetId">
  <div>
    <div>
      <p class="hidden">{{ rtColumnDisplay }}</p>
      <p class="hidden">{{ columnDisplay }}</p>
      <p class="hidden">{{ planColumnDisplay }}</p>
      <div
        class="block tool_group"
        :style="{
          top: this.mouse_y - 25 + 'px',
          left: this.mouse_x + 'px',
        }"
        :id="'tooltip' + this.widgetId"
      >
        <div v-if="isPlanTooltip" class="actc_tool text-white">
          <p :key="i" v-for="(msg, i) of planTooltipValues.split('</br>')">
            {{ msg }}
          </p>
        </div>
      </div>

      <TDNavbar
        :wellId="wellId"
        :wellboreId="wellboreId"
        :showAll="zoomOut"
        :onToggleSideBar="onToggleSideBar"
        :onChangeValues="onChangeValues"
        :onSaveBha="onSaveBha"
        :displayId="displayId"
        :widgetId="widgetId"
        :isSaved="isSaved"
        :time_zone="time_zone"
        :startTime="wellStartTime"
        :setDrilligAsDefault="setDrilligAsDefault"
      />

      <span class="hidden">{{ darkDark }}</span>
      <span class="hidden">{{ leftSideBarOpenForTD }}</span>

      <!-- {{leftSideBarOpenForTD}} -->

      <div class="flex_items">
        <div
          class="flex-item flex_1"
          :style="{
            // background:'var(--sidebarbg)',
            height: cHeight - 16 + 'px',
          }"
        >
          <TdLeftbar
            :wellId="wellId"
            :wellboreId="wellboreId"
            :sideBarForProp="sideBarForProp"
            :toggleSidebarProp="toggleSidebar"
            :assemblySelected="renderGraph"
            :torHklToggle="torHklToggle"
            :rtVsPlanToggle="rtVsPlanToggle"
            :rtToggle="rtToggle"
            :isRealtime="isRealtime"
            :isSaved="isSaved"
            :listCount="listCount"
            :bhaSelected="bhaSelected"
            :fmChange="fmChange"
            :planvsrt="planvsrt"
            :planvsreal="planvsreal"
            :zoomOnOff="zoomOnOff"
            :planId="planId"
            :bhaId="bhaId"
            :bhaName="bhaName"
            :selectedWellInfo="selectedWellInfo"
            :hklOrTor="hklOrTor != 'TORQUE'"
            :selectedSavedRun="selectedSavedRun"
            :isZoomFeature="isZoomFeature"
            :changeWellSelectHandler="resetGraph"
            :style="{height: cHeight - 16 + 'px'}"
          />
        </div>
        <div
          v-if="!planvsreal || isRTvsPlan"
          class="flex-item flex_2 flex"
          :style="{
            width:
              this.sideBarForProp == 'TD'
                ? this.isRTvsPlan
                  ? this.widgetWidth * (1 - 0.72) + '%'
                  : this.widgetWidth * (1 - 0.58) + '%'
                : this.isRTvsPlan
                ? this.widgetWidth * (1 - 0.7) + '%'
                : this.widgetWidth * (1 - 0.52) + '%',
            height: this.cHeight - 16 + 'px',    
          }"
        >
          <div
            class="w-full"
            :style="{
              background: 'var(--sidebarbg)'
            }"
            :id="'tvdTrajectoryTrend' + this.widgetId"
          >
            <CanvasLineChart
              :totl_height="cHeight"
              :totl_width="cWidth"
              :data_set="linedata"
              :min_X="minX"
              :min_Y="minY"
              :max_X="maxX"
              :max_Y="maxY"
              :diamondShapeData="diamondShapeData"
              :diamondShapeType="whichShow"
              :wellFormationData="cWellFormation"
              :geometryData="geometry"
              :enableZoom="isZoomFeature"
              :isRealtime="isRealtime"
              :id="'canvasTndid'"
              ref="canvasTndRef"
              :graphName="'PLANNED'"
              :key="cRefreshkey"
              :hklOrTor="hklOrTor"
              :unit="unit"
              :displayId="displayId"
            ></CanvasLineChart>
          </div>
        </div>
        <div
          v-if="planvsreal || isRTvsPlan"
          class="flex-item flex_2 flex"
          :style="{
            width:
              this.sideBarForProp == 'TD'
                ? this.isRTvsPlan
                  ? this.widgetWidth * (1 - 0.72) + '%'
                  : this.widgetWidth * (1 - 0.58) + '%'
                : this.isRTvsPlan
                ? this.widgetWidth * (1 - 0.7) + '%'
                : this.widgetWidth * (1 - 0.52) + '%',
            height: this.cHeightRT - 16 + 'px',
          }"
        >
          <div
            class="w-full"
            :style="{
              background:'var(--sidebarbg)'
            }"
            :id="'tvdTrajectoryTrendRT' + this.widgetId"
          >
            <CanvasLineChart
              :totl_height="cHeightRT"
              :totl_width="cWidthRT"
              :data_set="linedataRT"
              :min_X="minRTX"
              :min_Y="minRTY"
              :max_X="maxRTX"
              :max_Y="maxRTY"
              :diamondShapeData="diamondShapeDataRT"
              :diamondShapeType="whichShow"
              :wellFormationData="cWellFormation"
              :geometryData="geometry"
              :enableZoom="isZoomFeature"
              :isRealtime="isRealtime"
              :id="'canvasTnd_id_RT'"
              ref="canvasTndRTRef"
              :graphName="'REALTIME'"
              :key="cRefreshkey + 55"
              :hklOrTor="hklOrTor"
              :unit="unit"
              :displayId="displayId"
            ></CanvasLineChart>
          </div>
        </div>
      </div>
    </div>
    <div v-if="rtColumnDisplay || columnDisplay">
      <button
        class="flex-item flex legend_box info_btn flex mr-3 ml-auto pt-1 z-50"
      >
        <i class="fas fa-info-circle"></i>
      </button>
      <div class="info_popup p-2 absolute top-12 right-2 hidden">
        <div :class="'wrapper-cond-3'">
          <div class="" v-if="rtColumnDisplay">
            <ul>
              <p class="text-center text-xs legends">Real Time</p>
              <hr class="legends_hr_" />
              <li
                :key="i"
                v-for="(msg, i) of rtColumn"
                :style="{
                  color: this[msg],
                }"
                class="list_li ml-2"
              >
                <p class="mb-1">
                  &#9830;
                  <span class="break-all legend_txt">
                    {{ msg.toUpperCase() }}
                  </span>
                </p>
              </li>
            </ul>
          </div>
          <div class="" v-if="columnDisplay">
            <ul>
              <p class="text-center text-xs legends">Legends</p>
              <hr class="legends_hr_" />
              <svg width="190" height="250" xmlns="http://www.w3.org/2000/svg">
              <template v-for="(msg, i) of columns" :key="i">
                <line  x1="10" :y1="20 *i+10" x2="60" :y2="20 *i+10" :stroke="linedata[i]?.color" :stroke-width="linedata[i]?.width" :stroke-dasharray="linedata[i]?.lineType?.toString()"/>
                <text x="70" :y="20 *i +15" :fill="linedata[i]?.color">{{ msg.toUpperCase() }}</text>
              </template>
            </svg>
              <!-- <li
                :key="i"
                v-for="(msg, i) of columns"
                :style="{
                  color: this[msg],
                }"
                class="list_li ml-2"
              >
                <p class="mb-1">
                  &#9830;
                  <span class="break-all legend_txt">
                    {{ msg.toUpperCase() }}
                  </span>
                </p>

              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import apiService from "../../../api/services";
import TDNavbar from "../../Navbars/TDNavbar.vue";
import TdLeftbar from "../../Sidebar/TdLeftbar.vue";
import { useTndReplay } from "../../../composable/useTndReplay";
import CanvasLineChart from "./CanvasLineChart.vue";

export default {
  name: "BroomStickPlot",
  components: {
    TDNavbar,
    TdLeftbar,
    CanvasLineChart,
  },
  props: {
    openConfig: Function,
    displayId: String,
    widgetHeight: {
      type: Number,
      default: 200,
    },
    widgetWidth: {
      type: Number,
      default: 200,
    },
    gridColor: {
      type: String,
      default: "",
    },

    gridBackground: {
      type: String,
      default: "",
    },
    xAxisLabel: {
      type: String,
      default: "",
    },
    yAxisLabel: {
      type: String,
      default: "",
    },
    xAxisLabelSize: {
      type: Number,
    },
    yAxisLabelSize: {
      type: Number,
    },
    backgroundColor: {
      type: String,
      default: "white",
    },
    widgetId: String,
    isSwitched: {
      type: Boolean,
      default: false,
    },
    actualColor: {
      type: String,
      default: "red",
    },
    planColor: {
      type: String,
      default: "steelblue",
    },
    bhaStartTime: {
      type: String,
      default: "",
    },
    bhaEndTime: {
      type: String,
      default: "",
    },
    isReplay: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  data() {
    return {
      setDrilligAsDefault: false,
      wellStartTime: "",
      broomStickWatcher: null,
      realTimeoutDelay: 10000,
      megaDataRT: [],
      megaData: [],
      isRTvsPlan: false,
      selectedSavedRun: {},
      selectedWellInfo: {},
      isZoomFeature: false,
      mouse_x: 0,
      mouse_y: 0,
      isPlanTooltip: false,
      planTooltipValues: "",
      realtimeTimeOut: "",
      isSaved: false,
      whichShow: "drilling",
      isRealtime: true,
      sideBarForProp: "",
      width: this.widgetWidth,
      height: this.widgetHeight - 20,
      graphData: {
        // rows: [],
        rows: [],
        rowsPlan: [],
        columns: ["hkl", "depth"],
      },
      selectedTags: {
        x: "torque",
        y: "depth ",
      },
      hklOrTor: "TORQUE",
      columns: [],
      rtColumn: [],
      plannedColumn: [],
      colors: [],
      minX: 0,
      maxX: 100,
      minY: 0,
      maxY: 100,
      minRTX: 0,
      maxRTX: 100,
      minRTY: 0,
      maxRTY: 100,
      isZoomed: false,
      isZoomedRt: false,
      extent: [],
      extentRT: [],
      soColor: "",
      puColor: "",
      robColor: "",
      slackoff: "",
      pickup: "",
      rotateoffbottom: "",
      slackofftripin: "",
      pickuptripout: "",
      toggleSidebar: "-16",
      sideBarFor: "",
      tdopen: false,

      planId: "",
      bhaId: "",
      sourceid: "",
      bhaName: "",
      start_time: this.bhaStartTime,
      end_time: this.bhaEndTime,
      bhaCount: 0,
      selectedBhaSourceId: "",
      selected_start_time: "",
      selected_end_time: "",
      wellFormation: [],
      geometry: [],
      FM: true,
      planvsreal: false,
      tq_pu_ff1: "",
      tq_pu_ff2: "",
      tq_pu_ff3: "",
      tq_pu_ff4: "",
      tq_pu_ff5: "",
      tq_rob: "",
      tq_so_ff1: "",
      tq_so_ff2: "",
      tq_so_ff3: "",
      tq_so_ff4: "",
      tq_so_ff5: "",
      depthUnit: "(ft)",
      unit: " (k ft-lbf)",
      config: {
        minX: 0,
        minY: 0,
        maxX: 1886221572.1973333,
        maxY: 21000,
        unitsPerTickX: 10,
        unitsPerTickY: 10,
      },
      linedata: [],
      rtData: [],
      diamondShapeData: [],
      linedataRT: [],
      diamondShapeDataRT: [],
      cHeight: this.widgetHeight - 20,
      cWidth: this.widgetWidth,
      cHeightRT: this.widgetHeight -20,
      cWidthRT: this.widgetWidth,
      cRefreshkey: 555,
      cWellFormation: [],
    };
  },
  beforeUnmount() {
    this.broomStickWatcher();
    if (this.realtimeTimeOut) {
      clearTimeout(this.realtimeTimeOut);
    }
  },
  async mounted() {
    let dtls = this.$store.state.disp.displays[this.displayId];
    if (dtls) {
      this.wellId = dtls.wellId;
      this.wellboreId = dtls.wellboreId;
      this.logId = dtls.trajectoryLogId;
      this.wellboreState = dtls.wellboreState;
      this.logType = dtls.logType;
      this.table = dtls.table;
      this.time_zone = dtls.time_zone || "CST6CDT";
      this.wellStartTime = dtls.min;
      console.log("~~~ time zone alert : mounted ", dtls);
      this.clearRuningIntervals();
    }
    this.broomStickWatcher = this.$store.subscribe(async (mutation, state) => {
      switch (mutation.type) {
        case "disp/setDisplay":
          console.log("build svg:::::insubscribe");
          this.selectedSavedRun = {};
          this.selectedWellInfo = {};
          this.wellId = mutation.payload.wellId;
          this.wellboreId = mutation.payload.wellboreId;
          this.logId = mutation.payload.logId;
          this.status = mutation.payload.status;
          this.logType = mutation.payload.logType;
          this.logTypeId = mutation.payload.logTypeId;
          this.time_zone = mutation.payload.time_zone || "CST6CDT";
          this.wellStartTime = mutation.payload.min;
          console.log("~~~ time zone alert : mounted ", mutation.payload);
          this.isZoomFeature = false;
          this.mouse_x = 0;
          this.mouse_y = 0;
          this.isPlanTooltip = false;
          this.planTooltipValues = "";
          this.realtimeTimeOut = "";
          this.isSaved = false;
          this.whichShow = "drilling";
          this.isRealtime = true;
          this.sideBarForProp = "";
          this.width = this.widgetWidth;
          this.height = this.widgetHeight - 20;
          this.graphData = {
            // rows: [],
            rows: [],
            rowsPlan: [],
            columns: ["hkl", "depth"],
          };
          this.selectedTags = {
            x: "torque",
            y: "depth",
          };
          this.hklOrTor = "TORQUE";
          this.columns = [];
          this.rtColumn = [];
          this.plannedColumn = [];
          this.colors = [];
          this.minX = 0;
          this.maxX = 100;
          this.minY = 0;
          this.maxY = 100;
          this.isZoomed = false;
          this.isZoomedRt = false;
          this.extent = [];
          this.soColor = "";
          this.puColor = "";
          this.robColor = "";
          this.slackoff = "";
          this.pickup = "";
          this.rotateoffbottom = "";
          this.slackofftripin = "";
          this.pickuptripout = "";
          this.toggleSidebar = "-16";
          this.sideBarFor = "";
          this.tdopen = false;

          this.planId = "";
          this.bhaId = "";
          this.sourceid = "";
          this.start_time = this.bhaStartTime;
          this.end_time = this.bhaEndTime;
          this.bhaCount = 0;
          this.selectedBhaSourceId = "";
          this.selected_start_time = "";
          this.selected_end_time = "";
          this.wellFormation = [];
          this.geometry = [];
          this.FM = true;
          this.planvsreal = false;
          this.tq_pu_ff1 = "";
          this.tq_pu_ff2 = "";
          this.tq_pu_ff3 = "";
          this.tq_pu_ff4 = "";
          this.tq_pu_ff5 = "";
          this.tq_rob = "";
          this.tq_so_ff1 = "";
          this.tq_so_ff2 = "";
          this.tq_so_ff3 = "";
          this.tq_so_ff4 = "";
          this.tq_so_ff5 = "";
          this.clearRuningIntervals();
          await this.buildSVG();
          await this.buildSVGRT();
          this.cRefreshkey += 11;
          this.table = mutation.payload.table;
          this.setDrilligAsDefault = this.setDrilligAsDefault ? false : true;
          break;
        case "rect/DARK_MODE":
          this.cRefreshkey += 29;
          break;
        default:
          break;
      }
    });

    await this.buildSVG();
    await this.buildSVGRT();
    this.cRefreshkey += 13;
  },
  unmounted() {
    this.clearRuningIntervals();
  },
  computed: {
    darkDark() {
      // this.cRefreshkey += 29;
      return this.$store.state.rect.darkmode;
    },
    leftSideBarOpenForTD() {
      if (this.$store.state.rect.leftSideBarOpenForTD)
        this.onToggleSideBar("TD");

      return this.$store.state.rect.leftSideBarOpenForTD;
    },
    rtColumnDisplay() {
      return this.rtColumn.length && (this.isRealtime || this.planvsreal)
        ? true
        : false;
    },
    columnDisplay() {
      return this.columns.length ? true : false;
    },
    planColumnDisplay() {
      return this.plannedColumn.length && (!this.isRealtime || this.planvsreal)
        ? true
        : false;
    },
  },
  methods: {
    zoomOnOff() {
      this.isZoomFeature = !this.isZoomFeature;
      // this.zoomOut();
      this.cRefreshkey += 79;
    },
    fmChange() {
      this.FM = !this.FM;
      this.buildSVG();
      this.buildSVGRT();
      this.cRefreshkey += 4;
    },
    planvsrt() {
      this.planvsreal = !this.planvsreal;
      setTimeout(async () => {
        if (this.planvsreal) {
          await this.buildSVGRT();
          this.cRefreshkey += 6;
        } else {
          await this.buildSVG();
          this.cRefreshkey += 6;
        }
      }, 500);
    },
    async getWellFormations() {
      try {
        let formationMakersResponse =
          await apiService.WellServices.getWellFormations({
            well_id: this.wellId,
            wellbore_name: this.wellboreId,
          });
        this.wellFormation = formationMakersResponse.data.rows;
        //
      } catch (error) {
        console.error(error);
      }
    },
    async getWellGeometry() {
      try {
        let geometryResponse = await apiService.WellServices.getWellGeometry({
          well_id: this.wellId,
          wellbore_name: this.wellboreId,
        });
        this.geometry = geometryResponse.data.rows;
        //
      } catch (error) {
        console.error(error);
      }
    },
    async bhaSelected(run, isChecked) {
      if (isChecked) {
        this.selectedBhaSourceId = run.sourceid;
        this.planId = run.plan_id;
        this.bhaId = run.bha_id;
        this.sourceid = run.sourceid;
        this.bhaName = run.name;
        this.selected_start_time = run.start_time;
        this.selected_end_time = run.end_time;
        this.selectedSavedRun = run;
        //
      } else {
        this.selected_start_time = "";
        this.selected_end_time = "";
        this.selectedSavedRun = {};
      }
      await this.get3DGraphData({
        planId: this.planId,
        bhaId: this.bhaId,
        sourceid: this.sourceid,
      });
      await this.buildSVG();
      await this.buildSVGRT();
      this.cRefreshkey += 678;
    },
    async onSaveBha(run_name) {
      let tempBody = {
        // name: "BHA RUN " + (this.bhaCount + 1),
        name: run_name,
        bha_for: this.hklOrTor,
        well: this.wellId,
        wellbore: this.wellboreId,
        wellsection_id: "",
        bha_id: this.bhaId,
        plan_id: this.planId,
        sourceid: this.sourceid,
        start_time: this.start_time,
        end_time: this.end_time,
      };
      console.log(tempBody);
      //
      let response = "API NOT CALLED";
      if (
        tempBody.name &&
        tempBody.well &&
        tempBody.wellbore &&
        tempBody.bha_id &&
        tempBody.plan_id &&
        tempBody.start_time
      ) {
        response = await apiService.WellServices.saveBha(tempBody);
        this.isSaved = !this.isSaved;
        this.$toast.success("BHA run saved", {
          position: "top",
          duration: 1000,
        });
      } else {
        if (!tempBody.name || !tempBody.start_time) {
          if (!tempBody.name) {
            this.$toast.error("Please Enter Name", {
              position: "top",
              duration: 1000,
            });
          }
          if (!tempBody.start_time) {
            this.$toast.error("Please Select Start Date", {
              position: "top",
              duration: 1000,
            });
          }
        } else {
          this.$toast.error("Please Select Mandatory Details", {
            position: "top",
            duration: 1000,
          });
        }
      }
    },
    async onChangeValues(value) {
      if (this.wellId && this.wellboreId) {
        this.whichShow = value;
        // await this.buildSVG();
        // await this.buildSVGRT();
        this.cRefreshkey += 7;
      }
    },
    async rtToggle(toggle, planId, bhaId, sourceid, isRealtime) {
      console.log("build svg:::::rtToggle", this.isRTvsPlan);
      this.isRealtime = isRealtime;
      this.planId = planId;
      this.bhaId = bhaId;
      this.sourceid = sourceid;

      if (planId && bhaId) {
        await this.get3DGraphData({ planId, bhaId, sourceid });
        await this.buildSVG();
        await this.buildSVGRT();
        this.cRefreshkey += 5;
      }
    },
    listCount(count) {
      this.bhaCount = count;
    },
    async torHklToggle(toggle, planId, bhaId, sourceid) {
      try {
        this.hklOrTor = toggle;
        this.planId = planId;
        this.bhaId = bhaId;
        this.sourceid = sourceid;
        this.unit = " (kft-lbf)";

        if (toggle == "HOOKLOAD") this.unit = " (klbs)";
        this.selectedTags.x = toggle;
        if (planId && bhaId) {
          await this.get3DGraphData({ planId, bhaId, sourceid });
          await this.buildSVG();
          await this.buildSVGRT();
          this.cRefreshkey += 5;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async rtVsPlanToggle(toggle, planId, bhaId, sourceid, isRealtime) {
      try {
        this.isRTvsPlan = !this.isRTvsPlan;
        this.planId = planId;
        this.bhaId = bhaId;
        this.sourceid = sourceid;
        if (planId && bhaId) {
          await this.get3DGraphData({ planId, bhaId, sourceid });
          await this.buildSVG(false, isRealtime);
          this.cRefreshkey += 6;
          if (isRealtime || this.isRTvsPlan) {
            await this.buildSVGRT();
            this.cRefreshkey += 7;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async zoomOut() {
      // this.isZoomed = false;
      // this.isZoomedRt = false
      // // this.isZoomFeature = false
      // this.extent = []
      // this.extentRT = []
      await this.buildSVG();
      await this.buildSVGRT();
      this.cRefreshkey += 67;
      // this.$refs.canvasTndRef.resetZoom();
    },
    async renderGraph(planId, bhaId, sourceid, bhaCount, data, assembly) {
      console.log(
        "🚀 ➽ file: BroomStickPlot.vue:660  ➽ renderGraph  ➽ assembly ⏩",
        assembly
      );
      this.selectedWellInfo = data;
      this.planId = planId;
      this.bhaId = bhaId;
      this.sourceid = sourceid;
      this.bhaCount = bhaCount;

      this.assembly = assembly;
      await this.get3DGraphData({ planId, bhaId, sourceid });
      await this.getWellFormations();
      await this.getWellGeometry();
      await this.buildSVG();
      await this.buildSVGRT();
      this.cRefreshkey += 7;
    },
    async resetGraph() {
      console.log("reset graph");
      this.graphData = {
        rows: [],
        rowsPlan: [],
        columns: ["hkl", "depth"],
        rows_real_time: [],
      };
      this.wellFormation = [];
      this.cWellFormation = [];
      this.planId = "";
      this.bhaId = "";
      this.rtColumn = [];
      this.columns = [];
      this.set3DGraphData(true);
      this.buildSVG();
      this.buildSVGRT();
      this.cRefreshkey += 11;
    },
    setContextIdWidget(e) {
      this.$store.dispatch("disp/setContextId", {
        contextId: this.displayId,
        contextWidgetId: this.widgetId,
        contextType: "widget",
      });
    },

    async get3DGraphData(dtls) {
      try {
        const response = await apiService.WellServices.getTorqueAndDragData({
          graphFor: this.hklOrTor,
          ...dtls,
        });
        this.graphData = response.data;

        let columns = [];
        this.soColor = "#2195ff";
        this.puColor = "#00b545";
        this.robColor = "#FFC000";

        for (let i in response.data.rows[0]) {
          if (i != "bha_id" && i != "planid" && i != "md") {
            columns.push(i);
          }
        }
        this.columns = columns;

        if (this.isRealtime || this.planvsreal) {
          this.slackoff = "#ff006d";

          this.pickup = "#fd91ce";

          this.rotateoffbottom = "#ff6f00";

          this.slackofftripin = "#ac4dff";

          this.pickuptripout = "#53d4ff";
          let dateTimeQuery = {};
          if (this.selected_start_time || this.selected_end_time) {
            dateTimeQuery.start_time = this.selected_start_time;
            dateTimeQuery.end_time = this.selected_end_time;
          }
          if (this.isReplay) {
            this.setData(
              this.hklOrTor,
              dateTimeQuery,
              this.wellId,
              this.wellboreId,
              this.time_zone,
              dtls
            );
          } else {
            const rtResponse =
              await apiService.WellServices.getTorqueAndDragDataTime({
                graphFor: this.hklOrTor,
                ...dtls,
                ...dateTimeQuery,
                well_id: this.wellId,
                wellbore_name: this.wellboreId,
                time_zone: this.time_zone,
                assembly: this.assembly,
              });

            columns = [
              "pickup",
              "rotateoffbottom",
              "slackoff",
              "pickuptripout",
              "slackofftripin",
            ];
            if (rtResponse.data.rows[0] && rtResponse.data.rows[0].well_id) {
              this.rtColumn = columns;
            } else {
              this.rtColumn = [];
            }
            this.rtData = rtResponse.data.rows;
          }
        }
      } catch (error) {
        console.error(error);
      }
      this.set3DGraphData();
      return true;
    },

    set3DGraphData(isReset = false) {
      this.minX = 0;
      this.maxX = isReset ? 100 : 0;
      this.minY = 0;
      this.maxY = isReset ? 100 : 0;
      this.megaData = this.graphData.rows.map((ele, indexU) => {
        let tempResult = {};
        this.columns.map((col, index) => {
          if (index == 0 && indexU == 0) {
            this.minX = ele[col];
            this.maxX = ele[col];
          }

          tempResult[col] = ele[col];
          if (this.minX > ele[col]) {
            this.minX = ele[col];
          }
          if (this.maxX < ele[col]) {
            this.maxX = ele[col];
          }
        });
        tempResult["md"] = ele["md"];
        if (this.minY > ele["md"]) {
          this.minY = ele["md"];
        }
        if (this.maxY < ele["md"]) {
          this.maxY = ele["md"];
        }
        return tempResult;
      });
      this.minRTX = 0;
      this.maxRTX = isReset ? 100 : 0;
      this.minRTY = 0;
      this.maxRTY = isReset ? 100 : 0;
      this.megaDataRT = this.graphData.rows_real_time.map((ele, indexU) => {
        let tempResult = {};
        this.columns.map((col, index) => {
          if (index == 0 && indexU == 0) {
            this.minRTX = ele[col];
            this.maxRTX = ele[col];
          }

          tempResult[col] = ele[col];
          if (this.minRTX > ele[col]) {
            this.minRTX = ele[col];
          }
          if (this.maxRTX < ele[col]) {
            this.maxRTX = ele[col];
          }
        });
        tempResult["md"] = ele["md"];
        if (this.minRTY > ele["md"]) {
          this.minRTY = ele["md"];
        }
        if (this.maxRTY < ele["md"]) {
          this.maxRTY = ele["md"];
        }
        return tempResult;
      });

      if (this.megaDataRT && this.megaDataRT.length) {
        this.megaDataRT = [
          ...this.megaDataRT,
          ...this.megaData.slice(this.megaDataRT.length),
        ];
      } else {
        this.megaDataRT = this.megaData;
      }

      let abc = this.megaDataRT.map((ele, indexU) => {
        let tempResult = {};
        this.columns.map((col, index) => {
          if (index == 0 && indexU == 0) {
            this.minRTX = ele[col];
            this.maxRTX = ele[col];
          }

          tempResult[col] = ele[col];
          if (this.minRTX > ele[col]) {
            this.minRTX = ele[col];
          }
          if (this.maxRTX < ele[col]) {
            this.maxRTX = ele[col];
          }
        });
        tempResult["md"] = ele["md"];
        if (this.minRTY > ele["md"]) {
          this.minRTY = ele["md"];
        }
        if (this.maxRTY < ele["md"]) {
          this.maxRTY = ele["md"];
        }
        return tempResult;
      });

      console.log(" data[i] ", abc, this.megaDataRT);
      console.log(
        "this.minRTX = 0,this.maxRTX = 0",
        this.minRTX,
        this.maxRTX,
        this.minRTY,
        this.maxRTY
      );
    },

    async buildSVG(isCalledFromZoom) {
      this.cWidth = this.isRTvsPlan
        ? this.tdopen
          ? this.width * (1 - 0.55)
          : this.width * (1 - 0.48)
        : this.tdopen
        ? this.width * (1 - 0.1)
        : this.width;

      //structure for trajectory
      var broomStickData = this.megaData;
      //create axis

      if (this.FM && this.minX != this.maxX) {
        this.cWellFormation = this.wellFormation;
      } else {
        this.cWellFormation = [];
      }

      let colors = [];
      this.linedata = [];

      for (let index in this.columns) {
        // colors.push(this.getRandomColor());
        let color = "";
        let dashWidth = "";
        if (this.columns[index].indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (this.columns[index].indexOf("ff1") > -1) {
          dashWidth = [1, 1];
        } else if (this.columns[index].indexOf("ff2") > -1) {
          dashWidth = [10, 10];
        } else if (this.columns[index].indexOf("ff3") > -1) {
          dashWidth = [20, 5];
        } else if (this.columns[index].indexOf("ff4") > -1) {
          dashWidth = [15, 3, 3, 3];
        } else if (this.columns[index].indexOf("ff5") > -1) {
          dashWidth = [12, 3, 3];
        } else if (this.columns[index].indexOf("rob") > -1) {
          color = this.robColor;
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        this[this.columns[index]] = color;
      }

      for (let i of this.columns) {
        let col = i;
        let color = "";
        let dashType = "";
        let dashWidth = "";
        if (col.indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (col.indexOf("ff1") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [1, 1];
        } else if (col.indexOf("ff2") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [10, 10];
        } else if (col.indexOf("ff3") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [20, 5];
        } else if (col.indexOf("ff4") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [15, 3, 3, 3];
        } else if (col.indexOf("ff5") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [12, 3, 3];
        } else if (col.indexOf("rob") > -1) {
          color = this.robColor;
          dashType = "stroke-dasharray";
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        let det = {
          width: 2,
          color: color,
          lineType: dashWidth,
          data: [],
        };
        let data = [];
        broomStickData.forEach((d) => {
          data.push({ x: d[col], y: d.md, name: col });
        });
        det.data = data;
        this.linedata.push(det);
      }

      if (this.isRealtime || this.planvsreal) {
        // Scatter Plot
        // let torqueOrHkl = this.hklOrTor;
        // let diamondShape = symbol().type(symbolDiamond).size(30);
        let tt = [];
        for (let rtData of this.rtColumn) {
          let type = "drilling";
          if (rtData == "slackofftripin") type = "tripin";
          if (rtData == "pickuptripout") type = "tripout";
          this.rtData.forEach((d, idx) => {
            if (d[rtData]) {
              tt.push({
                x: d[rtData],
                y: d["dbtm"],
                color: this[rtData],
                type,
                dmea: d.dmea,
                time: new Date(d.time).toLocaleString("en-US", {
                  timeZone: this.time_zone,
                }),
                name: rtData,
              });
            }
          });
        }
        this.diamondShapeData = tt;
      }
    },
    async buildSVGRT(isCalledFromZoom) {
      this.cWidthRT = this.isRTvsPlan
        ? this.tdopen
          ? this.width * (1 - 0.55)
          : this.width * (1 - 0.48)
        : this.tdopen
        ? this.width * (1 - 0.1)
        : this.width;
      //structure for trajectory
      var broomStickData = this.megaDataRT;
      //create axis

      if (this.FM && this.minX != this.maxX) {
        this.cWellFormation = this.wellFormation;
      } else {
        this.cWellFormation = [];
      }

      let colors = [];
      this.linedataRT = [];
      for (let index in this.columns) {
        // colors.push(this.getRandomColor());
        let color = "";
        let dashWidth = "";
        if (this.columns[index].indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (this.columns[index].indexOf("ff1") > -1) {
          dashWidth = [1, 1];
        } else if (this.columns[index].indexOf("ff2") > -1) {
          dashWidth = [10, 10];
        } else if (this.columns[index].indexOf("ff3") > -1) {
          dashWidth = [20, 5];
        } else if (this.columns[index].indexOf("ff4") > -1) {
          dashWidth = [15, 3, 3, 3];
        } else if (this.columns[index].indexOf("ff5") > -1) {
          dashWidth = [12, 3, 3];
        } else if (this.columns[index].indexOf("rob") > -1) {
          color = this.robColor;
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        this[this.columns[index]] = color;
      }

      for (let i of this.columns) {
        let col = i;
        let color = "";
        let dashType = "";
        let dashWidth = "";
        if (col.indexOf("so") > -1) {
          color = this.soColor;
        } else {
          color = this.puColor;
        }
        if (col.indexOf("ff1") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [1, 1];
        } else if (col.indexOf("ff2") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [10, 10];
        } else if (col.indexOf("ff3") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [20, 5];
        } else if (col.indexOf("ff4") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [15, 3, 3, 3];
        } else if (col.indexOf("ff5") > -1) {
          dashType = "stroke-dasharray";
          dashWidth = [12, 3, 3];
        } else if (col.indexOf("rob") > -1) {
          color = this.robColor;
          dashType = "stroke-dasharray";
          dashWidth = [20, 3, 3, 3, 3, 3, 3, 3];
        } else {
          color = "white";
        }
        let det = {
          width: 2,
          color: color,
          lineType: dashWidth,
          data: [],
        };
        let data = [];
        broomStickData.forEach((d) => {
          data.push({ x: d[col], y: d.md, name: col });
        });
        det.data = data;
        this.linedataRT.push(det);
      }

      if (this.isRealtime || this.planvsreal) {
        // Scatter Plot

        let tt = [];
        for (let rtData of this.rtColumn) {
          let type = "drilling";
          if (rtData == "slackofftripin") type = "tripin";
          if (rtData == "pickuptripout") type = "tripout";
          this.rtData.forEach((d, idx) => {
            if (d[rtData]) {
              tt.push({
                x: d[rtData],
                y: d["dbtm"],
                color: this[rtData],
                type,
                dmea: d.dmea,
                time: new Date(d.time).toLocaleString("en-US", {
                  timeZone: this.time_zone,
                }),
                name: rtData,
              });
            }
          });
        }
        this.diamondShapeDataRT = tt;
      }
    },
    async updateChart(e) {
      this.extent = e.selection;
      console.log("this extent*******___: ", this.extent);
      this.isZoomed = true;
      if (e.selection) {
        this.buildSVG(true);
        // this.buildSVGRT(false);
      }
    },
    updateStart(event) {
      event.sourceEvent.stopPropagation();
    },
    updateStartRt(event) {
      event.sourceEvent.stopPropagation();
    },

    async updateChartRT(e) {
      this.extentRT = e.selection;
      console.log(
        "this extent rt values inside&&&&&&&&&& in updateChart: ",
        this.extentRT,
        e.selection
      );
      this.isZoomedRt = true;
      if (e.selection) {
        // this.buildSVG(false);
        this.buildSVGRT(true);
      }
    },

    dark() {
      this.$emit("dark");
    },

    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },

    light() {
      this.$emit("light");
    },

    async onToggleSideBar() {
      if (this.wellId && this.wellboreId) {
        if (this.sideBarForProp == "TD") {
          this.sideBarForProp = "";
          this.toggleSidebar = 0;
          this.tdopen = false;
        } else {
          this.sideBarForProp = "TD";
          this.toggleSidebar = -16;
          this.tdopen = true;
        }
        await this.buildSVG();
        await this.buildSVGRT();
        this.cRefreshkey += 1;
      } else {
        this.$toast.info("Please select well", {
          position: "top",
          duration: 1000,
        });
      }
    },
  },
  watch: {
    actualColor: async function (newVal, oldVal) {
      this.buildSVG();
      this.buildSVGRT();
    },
    bhaStartTime: async function (newVal, oldVal) {
      this.start_time = newVal;
    },
    bhaEndTime: async function (newVal, oldVal) {
      this.end_time = newVal;
    },
    planColor: async function (newVal, oldVal) {
      this.buildSVG();
      this.buildSVGRT();
    },
    gridColor: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    gridBackground: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    xAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    yAxisLabel: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    xAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    yAxisLabelSize: async function (newVal, oldVal) {
      try {
        this.buildSVG();
        this.buildSVGRT();
      } catch (error) {
        //
      }
    },
    widgetWidth: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.width = newVal;
        await this.buildSVG();
        this.cRefreshkey += 67;
      } catch (error) {
        //
      }
    },
    widgetHeight: async function (newVal, oldVal) {
      try {
        // await this.clearSvgHandler();
        this.height = newVal -20;
        this.cHeight = newVal - 20
        this.cHeightRT = newVal - 20
        await this.buildSVG();
        await this.buildSVGRT();
        this.cRefreshkey += 69;
      } catch (error) {
        //
      }
    },
    replayDetails: async function (response) {
      if(this.isZoomFeature){
        this.isZoomFeature = !this.isZoomFeature;
        this.cRefreshkey += 79;
      }
      let columns = [
        "pickup",
        "rotateoffbottom",
        "slackoff",
        "pickuptripout",
        "slackofftripin",
      ];
      if (response[0] && response[0].well_id) {
        this.rtColumn = columns;
      } else {
        this.rtColumn = [];
      }
      this.rtData = response;
      this.buildSVG();
      this.buildSVGRT();
      this.cRefreshkey += 79;
    },
  },
  setup() {
    const { setData, completeData, replayDetails, clearRuningIntervals } =
      useTndReplay();
    return {
      setData,
      completeData,
      replayDetails,
      clearRuningIntervals,
    };
  },
};
</script>
<style scoped>
.legend_box {
  position: absolute;
  background: var(--navBar2Bg);
  top: 50px;
  opacity: 1;
  right: 0;
  max-width: 12rem;
  max-height: 528px;
}
.margin_left_7 {
  margin-left: 13%;
}

.margin_left_0 {
  margin-left: 0;
}

.flex_1 {
  /* width: 13%; */
  transition-delay: 0.15s;
  /* background: #28282b; */
  /* position: absolute; */
  height: 100vh;
  /* background: #1b1a1f; */
}

.flex_2 {
  width: 130%;
  height: fit-content;
  overflow: hidden;
}

.flex_3 {
  width: 13%;
  position: absolute;
  right: 0;
  margin: 7px 0;
}

.flex_items {
  display: flex;
  background: #28282b;
}

.tool_group {
  position: fixed;
  display: inline-grid;
  /* left: 221px; */
  right: 162px;
  z-index: 99999;
}

.actc_tool {
  font-size: 10px;
  background: #000000;
  width: auto;
  position: absolute;
  padding: 2px 5px;
  /* border-radius: 20px; */
}

.legends_hr_ {
  width: 90%;
  margin-bottom: 15px;
}

.diamondShape_ {
  width: 50px;
  height: 20px;
}

.circle_symbol_ {
  width: 50px;
  height: 20px;
}

.dashes_ {
  width: 50px;
  height: 20px;
}

.wrapper {
  display: grid;

  /* grid-template-columns: repeat(auto-fit, minmax(160px, 1fr)); */
  gap: 5px;
  /* grid-auto-rows: minmax(350px, auto); */
  grid-auto-rows: 250px;
  margin: 9px 0;
  max-width: 1000px;
}

.wrapper-cond-1 {
  grid-template-columns: repeat(2, 195px);
}

.wrapper-cond-2 {
  grid-template-columns: repeat(2, 175px);
}

.wrapper-cond-3 {
  grid-template-columns: repeat(1, 175px);
  height: fit-content;
  width: 100%;
}

.list_li {
  font-size: calc(0.2vw + 0.2vh + 0.65vmin);
  align-content: center;
}

.info_btn {
  color: var(--searchColor);
}
.info_btn:hover {
  color: var(--activeTextColor);
}
.info_btn:hover + .info_popup {
  display: block;
}
.info_popup {
  background: var(--colorBoxPopUpInputBg);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  color: var(--textColor);
}
.wrapper > div {
  border: 1px solid #404040;
  height: 250px;
  overflow-y: auto;
}

.wrapper > div > ul {
  width: 80%;
  margin: 0 auto;
}

.diamondShape_ {
  width: 25%;
  /* word-break: break-all; */
}

.legend_txt {
  /* width: 66%; */
  /* display: flex; */
  align-items: center;
  font-weight: 500;
}
.diamond {
  font-size: 15px;
}
.legends {
  color: var(--textColor);
}
</style>
